
.resume-container{
   .tab-content{
       padding: 15px !important;
       background-color: $white;
       margin-top: 20px;
       border: 1px solid #EEEEEE;
       border-radius: 4px;
   }
}
.choose-theme{
    padding: 15px;
    background-color: $white;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    .theme-block-container{
        .theme-block{
            cursor: pointer;
            padding: 10px;
            padding-left: 0px;
            display: inline-block;
            .selected_theme{
                border: 2px solid black;
                border-radius: 8px;
            }
        }
    }
}
.theme-1{
    overflow: hidden;
    width: 100%;
    display: flex;
    .left-section{
        float: left;
        width: 30%;
        background-color: #003D74;
        color: $white;
        padding: 15px;
        .personal-info, .skill-info{
            .heading{
                background-color: #05294a;
                margin-left: -15px;
                margin-right: -15px;
                padding: 5px 15px;
                font-size: 16px;
            }
        }
        .skill-info{
            .progress{
                background-color:#05294a;
                border-radius: 0px;
                .bg-primary{
                    background-color: $white !important;
                }
            }
        }
        
    }
    .right-section{
        float: right;
        width: 70%;
        background-color: $white;
        padding: 15px;

        .blue-heading {
            color: #05294a;
            border-top: 2px solid #eeee;
            border-bottom: 2px solid #eeee;
            padding: 3px 0px;
        }
    }
}