.assessment-container{
    width: 95%;
    margin: auto;
}
.company-logos-assessment{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.width-250{
    width: 300px;
}
.gray-round-box{
    background-color: rgb(243, 240, 240);
    border-radius: 10px;
    padding: 0 10px;
}
.gray-round-box-r{
    background-color: rgb(241, 236, 236);
    border-radius: 50%;
    padding: 0 5px;
}
.box-ass{
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
}