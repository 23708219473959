.dashboard-page{
    .card{
        box-shadow: none;
        font-weight: 300;
        border: 1px solid #e5e5e5;
        .card-body{
            button{
                color: #447E63;
            }
        }
    }
}