.account-setting{
    .card{
        box-shadow: none;
    }
    .form-control.is-invalid{
        background-image: none;
    }
    .form-group{
        ul{
            li{
                img{
                    width: 15px;
                }
            }
        }
    }
}