.notifications-container{
    background: #FFFFFF;
box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
}
.underline-border{
    border-bottom: 1px solid #E5E5E5;
    margin: 0 ;
}
.not-text{
    font-family: Helvetica;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0px;
}
.not-button{
font-family: Helvetica;
font-size: 11px;
color: #447E63;

}
.notification-time{
    color:#7E8384;
    font-family: Helvetica;
font-size: 10px;
line-height: 12px;
}