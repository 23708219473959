.box-personal{
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.17);
    height: 130px;
    border: #dddddd;
}
.btn-sp{
    outline: none !important;
    border: none !important;
    background-color: transparent !important;
}
.top-border-box-g{
    border: #dddddd;
}