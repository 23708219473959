body[data-layout="horizontal"] .page-content.job-details,
body[data-layout="horizontal"] .page-content.job-dashbord{
    background-color: #F6F6F6;
}

.job-tabs{
    padding: 25px;
    text-align: left;
    border: 1px solid #eeee;
    background-color: $white;
    border-radius: 4px;

    .nav-tabs{
        border-bottom: 1px solid $dark-green;
    }
    .nav-item {
        & .active{
            background-color: $dark-green;
            border:none;
            padding-top: 7px;
            padding-bottom: 7px;
            color:$white;
            &.nav-link{
                color: $white;
            }
        }
        & .nav-link{
            color:$dark-green;
            border: none;
            &:hover{
                border: none;
            }
        }
        margin-bottom: 0px;
        height:33px;
    }
}

.application-tab{
    .bx-search-alt{
        font-size: 20px;
        top: 5px;
        right: 5px;
        color: #7E8384;
        position: absolute;
    }
    .row-header{
        padding: 15px 0px;
        background-color: $white;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 4px;
        border: 1px solid #E5E5E5;
    }
    
    .row-items{
        padding: 15px 0px;
        background-color: $white;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 4px;
        border: 1px solid #E5E5E5;

        &:hover{
            background: $light-bg-color;
        }
        .header-item{
            padding-left: 0px;
            padding-top: 0px;
            height: auto;
        }
        .dropdown-menu{
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
            border-radius: 4px 0px 4px 4px;
            right: 25px !important;
            z-index: 1;
        }
        .dripicons-dot{
            position: absolute;
            font-size: 40px;
            top: -11px;
            left: 0;
        }
        .text{
            margin-left: 10%;
        }
        .failed-text{
            color: #EB5757;
        }
        .pedning-text{
            color: #F2994A;
        }
    }
}

.rec-candidate-modal{
    .bx-search-alt{
        font-size: 20px;
        top: 5px;
        right: 5px;
        color: #7E8384;
        position: absolute;
    }
    .row-header{
        padding: 15px 0px;
        background-color: $white;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 4px;
        border: 1px solid #E5E5E5;
    }
    
    .row-items{
        padding: 15px 0px;
        background-color: $white;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 4px;
        border: 1px solid #E5E5E5;

        &:hover{
            background: $light-bg-color;
        }
        .header-item{
            padding-left: 0px;
            padding-top: 0px;
            height: auto;
        }
        .dropdown-menu{
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
            border-radius: 4px 0px 4px 4px;
            right: 25px !important;
            z-index: 1;
        }
        .dripicons-dot{
            position: absolute;
            font-size: 40px;
            top: -11px;
            left: 0;
        }
        .text{
            margin-left: 10%;
        }
        .failed-text{
            color: #EB5757;
        }
        .pedning-text{
            color: #F2994A;
        }
    }
    .rec-w{
        width:3% !important;
    }
    .form-check-input{
        margin-top: -0.7rem;
        margin-left: -0.25rem;
    }
}