.user-card {
  // min-width: 290px !important;
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  min-height: 135px;
  background-color: #ffffff;
  color: #44705c;

  cursor: pointer;
  .count_font {
    font-size: 0.8em;
  }
}
.b-g-green {
  background: #74b496;
  border-radius: 4px;
}
.f-color-white {
  color: white;
}
.b-g-white {
  background: white;
  color: #44705c;
}
