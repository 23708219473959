$dark-green: #213d30;
$light-green: #74b496;
$card-background-color: #f3f3f3;
$profile-border-color: #dde1df;
$light-text-color: #6f6f6f;
$text-black: #000000;
$border-bottom-color: #eeeeee;
$btn-grey-background: #c4c4c4;
$card-company-text-color: #111111;
$light-bg-color: #d5e8e0;
@font-face {
  font-family: "Helvetica";
  font-weight: normal;
  font-style: normal;
  src: url("../../assets/fonts/Helvetica.ttf") format("truetype");
}

body {
  font-family: "Helvetica";
}
.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.word-wrap {
  overflow-wrap: break-word;
}

.label-required {
  color: #f46a6a;
}

.form-group label {
  color: $light-text-color;
}

// .form-group input,.form-group select{
//     border: none;
//     border-radius: 0px;
//     border-bottom: 1px solid #E0E0E0;
//     padding: 0px 0px;
//     height: auto;
// }
.text-color-light {
  color: $light-text-color;
}

.link-forgot-password {
  color: #213d30 !important;
  font-weight: 600;
}

.form-group input::placeholder {
  color: $light-text-color;
  font-weight: 300;
}

.font-weight-200 {
  font-weight: 200;
}

.noti-icon .badge {
  left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
  float: right;
}

.rdw-editor-main {
  border: 1px solid #eeeef5;
  height: 239px;
}

.fcYNFc {
  background-color: #ffffff !important;
}

.task-box {
  border: 1px solid #e1e1e6;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.fc-event .fc-content {
  padding: 5px;
  color: #fff;
}

.chat-conversation .right .conversation-list {
  margin-right: 15px;
}

.external-event {
  &:hover {
    cursor: pointer;
  }
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #7cb342 !important;
}

//button background color
.bg-green {
  background-color: #213d30;
  color: #fff;
  &:hover {
    color: #fff;
  }
  &:focus {
    box-shadow: none;
  }
}

.bg-light-green {
  background-color: $light-green;
}

.btn-outline-light-green {
  color: $light-green;
  border-color: $light-green;
  &:hover {
    color: #fff;
    background-color: $light-green;
  }
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.1;
  }
}

.btn-outline-green {
  color: #213d30;
  border-color: #213d30;
  &:hover {
    color: #fff;
    background-color: #213d30;
    border-color: #213d30;
  }
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.1;
  }
}

.bg-grey {
  background-color: $btn-grey-background;
  color: $text-black;
  &:hover {
    color: $text-black;
  }
  &:disabled {
    opacity: 0.3;
  }
  &:focus {
    box-shadow: none;
  }
}

.btn-outline-shadow {
  color: #213d30;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  &:hover {
    color: #213d30;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  &:focus {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  }
}

.btn-outline-green {
  color: #213d30;
  border-color: #213d30;
  &:hover {
    color: #fff;
    background-color: #213d30;
    border-color: #213d30;
  }
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.1;
  }
}

.btn-outline-grey {
  color: $text-black;
  border-color: #4f4f4f;
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.1;
  }
}

//text color
.green-text-color {
  color: #213d30 !important;
  &:hover {
    color: #213d30 !important;
  }
}

.red-text-color {
  color: red;
  &:hover {
    color: red;
  }
}

.light-green-text-color {
  color: #447e63;
  &:hover {
    color: #447e63;
  }
}

.white-text-color {
  color: white;
}

//Date Css
.cust-date-icon {
  position: absolute;
  right: 5px;
  top: 4px;
  font-size: 18px;
  cursor: pointer;
}

.react-datepicker__input-container input::placeholder {
  color: #e0e0e0;
}

//Toast Notification
#toast-container > .toast-error {
  background-image: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

///Body
body[data-layout-size="boxed"] {
  background-color: #f6f6f6;
}

//page-content padding
body[data-layout="horizontal"] {
  .page-content {
    padding-top: #{$grid-gutter-width};
    // background-color: $white;
  }
}

//top header
body[data-layout="horizontal"][data-topbar="colored"] #page-topbar {
  background-color: $dark-green;
}

body[data-layout="horizontal"][data-layout-size="boxed"] #page-topbar {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.06);
}

.shift-right {
  margin-left: 15%;
}

.app-search {
  width: 70%;
  display: grid;
  grid-template-columns: 1fr;
}

body[data-layout="horizontal"][data-topbar="light"] .app-search .form-control {
  border: none;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #f3f3f9;
  box-shadow: none;
  border-radius: 6px;
}

.app-search span {
  left: unset;
  right: 15px;
  line-height: 30px;
}

body[data-layout="horizontal"][data-topbar="light"] a.header-item {
  &:hover,
  &.active {
    color: $dark-green;
    border-bottom: 3px solid $dark-green;
    // font-weight: bold;
    text-shadow: 0 0 0.65px $dark-green, 0 0 0.65px $dark-green;
  }
  padding-bottom: 22px;
}

body[data-layout="horizontal"][data-topbar="light"] .app-search span,
body[data-layout="horizontal"][data-topbar="light"]
  .app-search
  input.form-control::-webkit-input-placeholder {
  color: #74788d;
}

.navbar-header .logo.logo-dark {
  img {
    width: 160px;
  }
}

// React Table Css
.table.react-table {
  & tbody {
    background-color: $card-background-color;
  }
  & th,
  td {
    border: none;
  }
  & td {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    vertical-align: middle;
  }
}

// link underline
.sign-in-link {
  color: $dark-green;
  border-bottom: 1px solid $dark-green;
  &:hover {
    color: $dark-green;
    border-bottom: 1px solid $dark-green;
  }
}

.text-under-line {
  text-decoration-line: underline;
}

body::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(50, 50, 50, 0.25);
  border: 1px solid transparent;
  border-radius: 10px;
  background-clip: padding-box;
}

.font-weight-100 {
  font-weight: 100;
}

body[data-layout-size="boxed"] #layout-wrapper {
  box-shadow: none;
  background-color: #f6f6f6;
}

.dropdown-menu {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0px 4px 4px;
  a {
    color: $light-green;
  }
}

.job-form,
.application-tab,
.rec-candidate-modal,
.job-dashbord {
  .skill-container {
    min-width: 45px;
    padding: 0px 5px;
    border-radius: 45px;
    border: 1px solid #c4c4c4;
    position: relative;
    color: $white;
    background-color: #74b496;
    font-weight: 300;
    font-size: 14px;
  }
}

.job-form .year-text {
  position: absolute;
  right: 11%;
  top: 55%;
}

.cut-text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.no-border-rad {
  border-radius: 0 !important;
}

.skills-learns {
  padding-left: 0px;
  & .skills-items {
    background-color: #74b496;
    margin-bottom: 3px;
    margin-right: 0px;
    padding: 4px 8px;
    border-radius: 15px;
    color: $white;
    font-weight: 100;
    border-color: #74b496;
    & span {
      font-size: 14px;
      position: relative;
      color: #fff;
    }
  }
}

.profile-page {
  .avatar-md {
    img {
      height: 70px;
      width: 200px;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 992px) {
  .user-card {
    .count_font {
      font-size: 0.7em !important;
    }
  }
  .setting_w {
    text-align: right;
  }
  .application-tab .row-items .header-item {
    padding: 0px;
  }
  .job_setting_w {
    width: 2%;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .user-card {
    .count_font {
      font-size: 0.7em !important;
    }
  }
  .setting_w {
    text-align: right;
  }
  .application-tab .row-items .header-item {
    padding: 0px;
  }
  .job_setting_w {
    width: 2%;
  }
}

.notification_container {
  .dropdown-menu a {
    &:hover {
      color: #74b496;
    }
  }
  .btn:focus {
    box-shadow: none;
  }
}

.header-item.plus_sign {
  margin-right: 1em;
}

.navbar-header .header-profile-user {
  background-color: transparent;
  height: 27px;
  width: 27px;
  margin-bottom: 7px;
}

.btn-primary {
  background-color: $dark-green;
  border-color: $dark-green;
}

.web_display {
  display: flex;
}

.mobileDevice {
  .header-item.plus_sign {
    margin-right: 0;
  }
  .application_status_header {
    background-color: #f6f6f6;
  }
  .web_display {
    display: block;
  }
  .mob_display {
    display: flex;
    align-items: center;
  }
  .navbar-header .logo.logo-dark img {
    width: 111px;
  }
  .navbar-header {
    padding-left: 12px;
    padding-right: 0;
    .navbar-brand-box {
      padding: 0;
    }
    .header-font .btn {
      padding: 0.47rem 0.5rem;
    }
  }
  .twitter-bs-wizard
    .twitter-bs-wizard-nav
    .nav-item
    .nav-link
    .step-number::before {
    left: 57%;
    width: 87%;
  }
  .btn.header-item {
    padding: 0.47rem 0.5rem;
    padding-top: 3px;
  }
  .table-responsive {
    background-color: #fff;
  }
  .account-pages {
    .right-section {
      display: none;
    }
    .left-section {
      width: 100%;
      padding: 20px 0px;
    }
    .login-heading {
      margin-left: 0;
    }
    .login-form {
      margin-left: 0;
    }
    .form_wrapper {
      padding-left: 0 !important;
    }
  }
  .account-setting {
    margin-top: 90px !important;
  }
  .user-card {
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .job-tabs {
    padding: 10px;
    .nav-item {
      height: 31px;
      .nav-link {
        font-size: 0.825em;
        padding: 0.5rem;
      }
    }
  }
  .mobile_side_padding {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .mobile_candidate_name {
    margin-top: 1em;
  }
  .mobile_job_side_padding {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }
  .job-details {
    .mobile_job_detail_header {
      margin-bottom: 1rem !important;
      padding-right: 0 !important;
    }
  }
  .mobile_jobdetail_status_text {
    padding-left: 2rem !important;
    padding-right: 1.5rem !important;
    margin-left: 3rem !important;
    margin-top: 0 !important;
  }
}

body[data-layout="horizontal"].mobileDevice .page-content {
  padding-top: 80px;
}

@media (max-width: 380px) {
  .navbar-brand-box {
    display: block;
  }
}

.toast-top-right .toast-success {
  border: 2px solid #34c38f !important;
  background-color: #34c38f !important;
}

.font-size-36 {
  font-size: 36px !important;
}

.text_light_green {
  color: #34c38f;
}

.text_light_green:hover {
  color: #248763;
}

input[type="checkbox"] {
  visibility: hidden;
}

.main {
  margin-left: 0.5rem;
}

.geekmark {
  position: absolute;
  top: 8px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 2px solid #eeeeee;
  border-radius: 4px;
}

.main input:checked ~ .geekmark {
  background-color: $light-green;
  border: none;
}

.main input:checked ~ .geekmark:after {
  display: block;
}

.geekmark:after {
  content: "";
  position: absolute;
  display: none;
}

.main .geekmark:after {
  left: 8px;
  bottom: 6px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(52deg);
}

div {
  overflow-wrap: normal;
  white-space: pre-wrap;
}

.overflow-auto {
  overflow: auto;
}

/* React Tag Styles Start */

div.ReactTags__tags {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 4px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  position: static;
}

/* Styles for the input */

div.ReactTags__tagInput {
  width: 100%;
  display: inline-block;
  border: none !important;
}

div.ReactTags__tagInput:focus {
  border: none !important;
  outline: none;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  width: 100%;
  // border: 1px solid #eee;
  padding: 0 4px;
  border: none !important;
  border-color: transparent;
  outline: none;
}

/* Styles for selected tags */

div.ReactTags__selected span.ReactTags__tag {
  color: $white;
  background-color: #74b496;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 2px 5px;
  cursor: move;
  border-radius: 20px;
}

div.ReactTags__selected a.ReactTags__remove {
  color: $white;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */

div.ReactTags__suggestions {
  position: absolute;
  z-index: 1;
  width: 100%;
  overflow: visible;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  max-height: 300px;
  overflow: auto;
  letter-spacing: normal;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e5e5ee;
  padding-left: 0px;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  margin-left: 0px;
  font-family: inherit;
}

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  margin-left: 0px;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
  margin-left: 0px;
}

/* React Tag Styles End */