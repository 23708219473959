.custom_radio__input {
    display: none;
}

.custom_radio {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    padding-right:60px;
}

.custom_radio__radio {
    width: 1.25em;
    height: 1.25em;
    border: 2px solid #d8e4e2;
    border-radius: 50%;
    margin-right: 4px;
    box-sizing: border-box;
    padding:2px;

}

.custom_radio__radio::after {
    content: "";
    width:100%;
    height: 100%;
    display: block;
    background:#74B496;
    border-radius: 50%;
  
    transform:scale(0);
    transition: transform 0.15s;
}

.custom_radio__input:checked  +  .custom_radio__radio::after {
    transform:scale(1);
}