.user-profile{
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    border: 5px solid rgb(247, 243, 243);
}
.no-hover{
    text-decoration: none;
    color: rgb(8, 43, 8);
}

.image-profile{
    display: flex;
    justify-content: center;
}

.f-20{
    font-size: 20px;
}
.f-12{
    font-size: 12px;
}
.f-18{
    font-size: 18px;
}
.f-16{
    font-size: 16px;
}


.small-blurred-text{
    font-size: 11px;
    color: gray;
}
.f-w{
    color: white;
}
.f-g{
    color: rgb(10, 82, 10);
}
.f-w-200{
    font-weight: 500;
}
.f-w-100{
    font-weight: 300;
}
.round-circle{
    border-right: 2px solid rgb(6, 71, 6);
    border-top: 2px solid rgb(6, 71, 6);
    border-radius: 50px;
    padding: 0px 10px;
    background: #74B496;
}

.active-applications ul li{
    list-style: none;
    margin: 0;
    
}
.active-applications ul{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline;
    margin: 0;
    padding:0px;
}
.active-applications{
    background: #FFFFFF;

}
.profile-section{
    background: #FFFFFF;
padding: 10px ;
border: 2px solid #E5E5E5;
border-radius: 4px;
}
.last-section{
    background: #FFFFFF;
    padding: 10px;
    border: 2px solid #E5E5E5;
    border-radius: 4px;
}
